import React from "react";

import styles from "@/styles/infScroll.module.scss";

import CloudFlareIcon from "@/components/Icons/CloudFlareIcon";
import WorldCoinIcon from "@/components/Icons/WorldCoin";
import AmazonIcon from "@/components/Icons/AmazonIcon";
import GoogleIcon from "@/components/Icons/GoogleIcon";
import DeliveryHeroIcon from "@/components/Icons/DeliveryHeroIcon";

const InfiniteCarousel = () => {
  return (
    <>
      <div className={styles.logos}>
        <div className={styles.scroll}>
          <DeliveryHeroIcon className="h-[5rem] w-auto ml-[4rem]" />
          <GoogleIcon className="h-[5rem] w-auto ml-[4rem]" />
          <AmazonIcon className="h-[5rem] w-auto ml-[4rem]" />
          <WorldCoinIcon className="h-[5rem] w-auto ml-[4rem]" />
          <CloudFlareIcon className="h-[5rem] w-auto ml-[4rem]" />
        </div>
        <div className={styles.scroll}>
          <DeliveryHeroIcon className="h-[5rem] w-auto ml-[4rem]" />
          <GoogleIcon className="h-[5rem] w-auto ml-[4rem]" />
          <AmazonIcon className="h-[5rem] w-auto ml-[4rem]" />
          <WorldCoinIcon className="h-[5rem] w-auto ml-[4rem]" />
          <CloudFlareIcon className="h-[5rem] w-auto ml-[4rem]" />
        </div>
      </div>
    </>
  );
};

export default InfiniteCarousel;
