"use client";

import { useTranslations } from "next-intl";
import Link from "next/link";

import styles from "@/styles/page.module.scss";

import { AnimatedCard } from "@/components/AnimatedCard";
import InfiniteCarousel from "@/components/InfiniteCarousel";
import SignUpForm from "@/components/SignUpForm";

import HydraIcon from "@/components/Icons/HydraIcon";
import { HydraButton } from "@/components/HydraButton";

import BranchIcon from "@/components/Icons/BranchIcon";
import BulbIcon from "@/components/Icons/BulbIcon";
import ServiceIcon from "@/components/Icons/ServiceIcon";
import RibbonIcon from "@/components/Icons/RibbonIcon";
import { HydraCard } from "@/components/HydraCard";

export default function Home() {
  const t = useTranslations("Landing");

  return (
    <>
      <main>
        <section id="hero" className={[""].join(" ")}>
          <div className="inset-0 bg-transparent absolute flex items-center content-center w-full  overflow-hidden">
            <div className="relative w-full h-full">
              <div className="w-full h-full absolute md:right-auto top-[35vh] md:mt-[15rem] md:top-auto md:mt-[15rem]">
                <BranchIcon className="h-[90vh] w-auto max-w-full" />
              </div>
            </div>
          </div>
          <div className={[styles.homeSection, "flexWrap"].join(" ")}>
            <div className={[styles.heroSectionCTA, ""].join(" ")}>
              <h1>{t("hero-title")}</h1>

              <h2>{t("hero-cta")}</h2>

              <Link autoFocus={false} href="#contact">
                <HydraButton autoFocus={false}>
                  {t("hero-cta-button")}{" "}
                </HydraButton>
              </Link>
            </div>
            <div className="flex w-full place-content-end md:block md:w-auto  mb-[1.5rem]">
              <div className="w-fit content-end mr-[10vw] md:content-normal md:m-0"></div>
              <AnimatedCard
                hasMaxW
                rotation={18}
                afterRotation={12}
                rotationHover={0}
              >
                <div className="h-[20vh] md:h-[35vh] flex justify-center items-center  py-[1rem] pt-[0rem] px-[1rem] md:pt-[5rem] md:pb-[5rem]">
                  <HydraIcon
                    style={{
                      width: "auto",
                      maxWidth: "calc(100vw - 10rem)",
                      height: "100%",
                    }}
                  />
                </div>
              </AnimatedCard>
            </div>
          </div>
        </section>
        <section id={"howTo"} className={[styles.howToBanner].join(" ")}>
          <div className={[styles.howToSection].join(" ")}>
            <div
              className={[
                styles.howToContent,
                " md:w-[70rem] md:px-2 pt-8 mb-[2rem]",
              ].join(" ")}
            >
              <div className={[styles.content_logo].join(" ")}>
                <HydraIcon sx={{ width: "auto", height: "auto" }} />
              </div>
              <h1>{t("howTo-title")}</h1>
            </div>
            <div className={[styles.howToTrophies, "pb-8"].join(" ")}>
              <HydraCard hasMaxW bordered>
                <span className="min-h-[20rem] flex flex-col items-center justify-start  text-center">
                  <h2>{t("howTo-first-trophy-title")}</h2>
                  <BulbIcon className="w-[4rem] h-[4rem] mb-[2rem]" />
                  <h3>{t("howTo-first-trophy-paragraph")}</h3>
                </span>
              </HydraCard>
              <HydraCard hasMaxW bordered>
                <span className="min-h-[20rem] flex flex-col items-center justify-start  text-center">
                  <h2>{t("howTo-second-trophy-title")}</h2>
                  <ServiceIcon className="w-[4rem] h-[4rem] mb-[2rem]" />
                  <h3>{t("howTo-second-trophy-paragraph")}</h3>
                </span>
              </HydraCard>
              <HydraCard hasMaxW bordered>
                <span className="min-h-[20rem] flex flex-col items-center justify-start  text-center">
                  <h2>{t("howTo-third-trophy-title")}</h2>
                  <RibbonIcon className="w-[4rem] h-[4rem] mb-[2rem]" />
                  <h3>{t("howTo-third-trophy-paragraph")}</h3>
                </span>
              </HydraCard>
            </div>
          </div>
        </section>
        <section id={"clients"} className={[styles.clientsBanner].join(" ")}>
          <div className={[styles.clientsSection].join(" ")}>
            <h1>{t("clients-title")}</h1>
            <div className={[styles.clientCarousel].join(" ")}>
              <InfiniteCarousel />
            </div>
          </div>
        </section>
        <section id="contact" className={["overflow-hidden"].join(" ")}>
          <div className={[styles.signUpSection, "maxWidth"].join(" ")}>
            <div>
              <h1>{t("signUp-title")}</h1>
              <h2>{t("signUp-cta")}</h2>
            </div>
            <div className={styles.titles}>
              <SignUpForm />
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
